import * as go from 'gojs';
import { DiagramModel } from '@/types/diagram';
import { isShape } from '@/bridge/settings/shapeSettings';
import { LinkCategory } from '@/bridge/enums/partCategories';

export type PrintSize = 'A1'|'A2'|'A3'|'A4'|'A5'|'SVG';

export type PrintSizeDetails = Record<PrintSize, {
  width: number;
  height: number;
  partition: {
    diagram: number;
    legend: number;
  };
}>;

export type DiagramFileType = 'svg'|'png'|'jpeg'|'pdf'|'json';

export type ExportOptions = {
  shapes: boolean;
  legend: boolean;
  connections: boolean;
  dependencies: boolean;
  label: boolean;
  code: boolean;
  status: boolean;
  description: boolean;
  presets: boolean;
  defaultLayerColor: boolean;
  printSize: PrintSize;
  fileType: DiagramFileType;
  diagramDivId: string;
  legendDivId: string;
  parts?: go.List<go.Part>;
};

export type ExportDiagramData = {
  legend: go.Diagram;
  diagram: go.Diagram;
  exportOptions: ExportOptions;
  diagramDetails: DiagramModel;
};

export function getExportedParts(data: ExportDiagramData): go.List<any> {
  const { exportOptions, diagram } = data;
  const parts = new go.List<any>();
  const nodes = diagram.nodes.filter((n) => !(isShape(n) && !exportOptions.shapes));
  const links = diagram.links.filter((l) => {
    if (l.category === LinkCategory.CONNECTION) {
      return data.exportOptions.connections;
    }
    return data.exportOptions.dependencies;
  });

  parts.addAll(nodes);
  parts.addAll(links);

  return parts;
}

export const printSizeDetails: PrintSizeDetails = {
  A1: {
    width: 9933,
    height: 7016,
    partition: {
      diagram: 0.95,
      legend: 0.05,
    },
  },
  A2: {
    width: 7016,
    height: 4960,
    partition: {
      diagram: 0.95,
      legend: 0.05,
    },
  },
  A3: {
    width: 4960,
    height: 3508,
    partition: {
      diagram: 0.92,
      legend: 0.08,
    },
  },
  A4: {
    width: 3508,
    height: 2480,
    partition: {
      diagram: 0.88,
      legend: 0.12,
    },
  },
  A5: {
    width: 2480,
    height: 1748,
    partition: {
      diagram: 0.85,
      legend: 0.15,
    },
  },
  SVG: {
    width: 1240,
    height: 874,
    partition: {
      diagram: 0.85,
      legend: 0.15,
    },
  },
};

export function getScale(diagram: go.Diagram, size: PrintSize, partition: number) {
  const widthRatio = (printSizeDetails[size].width * partition) / diagram.documentBounds.width;
  const heightRatio = (printSizeDetails[size].height - 100) / diagram.documentBounds.height;

  return widthRatio < heightRatio ? widthRatio : heightRatio;
}

export function createDownloadLink(fileName: string, href: string) {
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', href);
  linkElement.setAttribute('download', fileName);
  linkElement.click();
  linkElement.remove();
}
