import * as go from 'gojs';
import { ShapeSettings, NodeColor, ShapeData } from '@/bridge/types/diagramModel';
import { ShapeCategory } from '@/bridge/enums/partCategories';
import { getPartModelData } from '@/bridge/settings/common';
import { FontStyle, FontWeight, TextAlign, VerticalAlignment } from '@/bridge/enums/textStyle';

export const getDefaultShapeSettings = (category?: ShapeCategory) => {
  const color = {
    backgroundColor: 'rgb(222,222,222)',
    borderColor: 'rgba(0, 0, 0, 1)',
    textColor: 'rgba(0, 0, 0, 1)',
  };

  if (category === ShapeCategory.TEXT) {
    color.backgroundColor = 'rgba(255,244,128,0.2)';
  }

  return {
    label: '',
    color,
    size: {
      width: 80,
      height: 80,
    },
    textStyle: {
      fontSize: 18,
      fontWeight: FontWeight.NORMAL,
      fontStyle: FontStyle.NORMAL,
      fontFamily: 'DIN Next LT Pro',
      textAlign: TextAlign.LEFT,
      verticalAlignment: VerticalAlignment.TOP,
      isUnderline: false,
    },
    borderWidth: category && category === ShapeCategory.TEXT ? 1 : 2,
  };
};

export const shapeMap: Record<ShapeCategory, ShapeSettings> = {
  [ShapeCategory.TEXT]: {
    geometry: 'M3 18h12v-2H3v2zM3 6v2h18V6H3zm0 7h18v-2H3v2z',
    ...getDefaultShapeSettings(ShapeCategory.TEXT),
    borderWidth: 1,
  },
  [ShapeCategory.CLOUD]: {
    geometry:
      'M 471.591 4.16889 C 469.929 4.21086 468.323 4.32269 466.654 4.45541 C 439.947 6.57896 416.375 20.1092 404.401 40.2701 L 398.052 55.5271 C 399.422 50.2398 401.525 45.112 404.401 40.2701 C 383.045 22.6072 353.071 14.4548 323.454 18.2799 C 293.837 22.105 268.116 37.4453 254.059 59.6816 C 214.376 40.1709 164.911 41.3526 126.643 62.7617 C 88.3756 84.1708 67.979 122.077 74.178 160.321 L 78.2342 175.864 C 76.4012 170.772 75.0324 165.592 74.178 160.321 L 73.5608 161.753 C 40.2962 164.647 13.0151 185.251 5.92907 212.825 C -1.15701 240.399 13.5776 268.692 42.4343 282.95 L 62.8025 288.623 C 46.5206 284.401 57.6979 287.489 42.4343 282.95 C 20.1761 302.854 15.0541 332.321 29.7368 356.657 C 44.4195 380.992 75.7764 395.005 108.126 391.827 L 127.79 387.672 C 121.463 389.786 114.892 391.162 108.126 391.827 C 126.491 418.815 156.942 438.502 192.688 446.337 C 228.434 454.171 266.402 449.541 298.059 433.515 C 323.885 466.028 369.723 483.105 415.687 477.352 C 461.652 471.599 499.568 444.013 513.035 406.582 L 517.708 385.667 C 517.008 392.772 515.49 399.758 513.035 406.582 C 544.65 423.133 584.418 424.066 617.083 409.018 C 649.749 393.97 670.131 365.275 670.43 334.022 L 665.852 320.176 L 669.099 332.819 C 669.108 333.138 670.749 335.789 670.43 334.022 C 712.847 334.377 748.877 305.362 765.044 272.063 C 781.212 238.763 775.096 200.8 748.996 172.283 C 759.822 151.051 759.179 126.9 747.232 106.097 C 735.285 85.2952 713.248 69.8339 686.743 63.7645 C 680.812 36.1936 656.362 14.0158 624.049 6.74763 C 591.737 -0.520546 557.33 8.44176 535.696 29.8123 L 522.558 47.5047 C 525.915 41.1067 530.299 35.1431 535.696 29.8123 C 520.446 13.0166 496.526 3.53943 471.591 4.16897 L 471.591 4.16889 Z',
    ...getDefaultShapeSettings(ShapeCategory.CLOUD),
    borderWidth: 2,
  },
};

export function getAvailableShapes(): ShapeCategory[] {
  return Object.keys(shapeMap) as ShapeCategory[];
}

export function isShape(category: any): boolean {
  return getAvailableShapes().includes(category);
}

export function shapeSettings(part?: go.Part): ShapeSettings {
  if (!part) {
    return getDefaultShapeSettings();
  }

  return part.data;
}

export function shapeColor(part: go.Part): NodeColor {
  const defaultSettings = shapeMap[part.category as ShapeCategory];
  const { data }: { data: ShapeData } = part;

  const md = getPartModelData(part);

  if (!md) return defaultSettings.color;

  if (data.preset) {
    const preset = md.nodePresets[data.preset];
    if (preset && preset.color) {
      return preset.color;
    }
  }

  return defaultSettings.color;
}
