















































import Vue from 'vue';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import AppModal from '@/components/AppModal.vue';
import { createDownloadLink } from '@/bridge/print/printUtils';

export default Vue.extend({
  name: 'ExportBtn',
  components: {
    Btn,
    AppModal,
  },
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    name: {
      type: String,
      required: true,
    },
    exportUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        pdf: false,
        csv: false,
      },
    };
  },
  methods: {
    async exportTo(type: 'csv'|'pdf') {
      this.loading[type] = true;
      try {
        const url = this.exportUrl.indexOf('?') > 0
          ? this.exportUrl.split('?').join(`/${type}?`)
          : `${this.exportUrl}/${type}`;
        const data = await ApiService.get(url);
        const encodedUri = encodeURI(data);
        createDownloadLink(`${this.name}_export.${type}`, encodedUri);
      } catch {
        notify.danger(`Could not export ${this.name} list to ${type} at the moment`);
      }
      this.loading[type] = false;
    },
  },
});
